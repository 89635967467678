.root {
  margin: 16px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ccc;

  .header {
    margin: 8px 8px 24px 12px;
    display: flex;
    align-items: start;
    justify-content: space-between;
    font-weight: 400;
    font-size: 12px;
    color: #7e86a0;
    .amount {
      font-weight: 600;
      font-size: 20px;
      color: #404558;
    }
    .selection {
      display: flex;
      gap: 2px;
      border-radius: 4px;
      background-color: #f5f6f7;
      .period {
        cursor: default;
        padding: 4px 6px;
        margin: 2px;
        &.active {
          background: white;
          color: #536074;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
          border-radius: 4px;
        }
      }
    }
    .additional {
      padding: 6px 8px;
      background: #f5f6f7;
      border-radius: 4px;
      font-weight: 400;
      font-size: 12px;
      color: #404558;
    }
  }

  .chart {
    :global(.recharts-cartesian-axis-tick) text {
      font-weight: 400;
      font-size: 10px;
      color: #536074;
    }
    .tooltip {
      padding: 12px;
      border-radius: 4px;
      border: 1px solid var(--primary);
      font-size: 12px;
      background-color: #fffa;
      .date {
        font-weight: 500;
        margin-bottom: 4px;
      }
      .value {
        text-transform: capitalize;
        color: var(--primary);
      }
    }
  }
}
