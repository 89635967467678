@import "../../../lib/styles/invoices/status.scss";

.root {
  width: 100%;
  padding: 0 16px;
  .cell {
    border-bottom: none !important;
  }
  .row {
    cursor: pointer;
    &:hover {
      .glCell {
        .expand {
          display: block;
        }
      }
      .approverCell {
        .expand {
          display: block;
        }
      }
    }
    font-size: 11px;
    &:global(.MuiDataGrid-row):hover {
      background: #5484ff08;
      .vendorCell {
        color: var(--primary);
      }
    }
    .statusCell {
      @extend %status;
    }
    .emailCell {
      color: #6d7175b2;
    }
    .vendorCell {
      font-size: 12px;
      font-weight: 500;
    }
    .dueCell {
      font-weight: 500;
    }
    .glCell {
      button {
        justify-content: left;
        overflow: hidden;
        padding: 6px 10px;
        border-radius: 6px;
        font-weight: 400;
        background-color: #00000005;
      }
      span > svg {
        font-size: 16px;
        color: #adb2c280;
        transform: rotateY(180deg);
      }
      .expand {
        display: none;
      }
    }
    .approverCell {
      .expand {
        display: none;
      }
    }
    .actionsCell {
      justify-content: right;
      svg {
        font-size: 16px;
      }
      .more {
        display: block;
        margin: auto;
      }
      .action {
        display: none;
        svg {
          margin-right: 6px;
        }
        &:hover {
          color: var(--primary);
        }
      }
      .actionStyle2 {
        display: none;
        svg {
          margin-right: 0.5px;
        }
        &:hover {
          color: var(--primary);
        }
      }
      .actionStyle3 {
        display: none;
        svg {
          margin-right: 25px;
        }
        &:hover {
          color: var(--primary);
        }
      }
      &:hover {
        .more {
          display: none;
        }
        .action {
          display: block;
        }
        .actionStyle2 {
          display: block;
        }
        .actionStyle3 {
          display: block;
        }
      }
    }
  }
  .header :global(.MuiDataGrid-columnHeaderTitle) {
    font-weight: 600;
    font-size: 10px;
    text-transform: uppercase;
    color: rgba(64, 69, 88, 0.7);
  }
  :global(.MuiCheckbox-root) {
    color: #7e86a0;
    &:global(.Mui-checked) {
      color: var(--primary);
    }
    svg {
      font-size: 16px;
    }
  }
  :global(.MuiDataGrid-columnHeader) :global(.MuiCheckbox-root) {
    opacity: 0;
    &:hover,
    &:global(.Mui-checked) {
      opacity: 1;
    }
  }
  :global(.MuiDataGrid-columnSeparator) svg {
    color: #f8f8f8;
  }
  :global(.MuiDataGrid-columnHeader).header,
  :global(.MuiDataGrid-columnHeader):global(.MuiDataGrid-columnHeaderCheckbox),
  .row :global(.MuiDataGrid-cell) {
    &:focus-within {
      outline: none;
    }
  }
}
// table toolbar
.gridToolbar {
  justify-content: space-between;
  padding: 8px 8px 0;
  .tabsContainer {
    width: 100%;
    border-bottom: 1px solid #eee;
    margin-bottom: 10px;
  }
  .tabs {
    .tab {
      font-weight: 500;
      font-size: 12px;
      padding: 12px;
      margin-right: 8px;
      cursor: pointer;
      display: inline-block;
      .chip {
        cursor: pointer;
        height: auto;
        font-size: 10px;
      }
      &:hover {
        background-color: #eee4;
      }
      &.active {
        color: #546fff;
        border-bottom: 2px solid #546fff;
        .chip {
          background: #546fff;
          color: white;
        }
      }
    }
  }
  .selectionOptions {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 0 12px;
    width: 100%;

    .count {
      font-size: 12px;
      font-weight: 500;
      color: #1f2129;
      min-width: 200px;
    }

    .exportButton {
      font-size: 12px;
      font-weight: 500;
      color: #536074;
      background: white;
      border-color: #d6d8e080;
    }
  }
}

// Filters
.confirmation {
  font-size: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  span {
    transform: scale(0.6);
    padding: 0;
  }
}

.smallfont {
  font-size: 12px !important;
}
.smallerfont {
  font-size: 9px !important;
}
.buttoncolor {
  color: var(--primary) !important;
}
.delete {
  width: 100%;
  color: grey;
  &:hover {
    color: var(--primary);
  }
  svg {
    font-size: 16px;
    float: right;
  }
}
.selectInput {
  font-weight: 400 !important;
  font-size: 12px !important;
}

.glCell div {
  max-width: 180px;
}

div::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

div::-webkit-scrollbar-thumb {
  background: rgb(220, 220, 220);
  border-radius: 4px;
}

.filterDiv {
  flex-wrap: wrap;
  .buttonText {
    font-weight: 500;
    font-size: 12px;
    color: #536074;
    border-radius: 4px;
    white-space: nowrap;
    border-color: rgba(214, 216, 224, 0.5);
    &:hover {
      border-color: rgba(214, 216, 224, 0.5);
      background: #f5f6f7;
    }
  }
}

.filterDiv::-webkit-scrollbar {
  display: none !important;
}

.approverBtn {
  background: "unset";
  display: flex;
  justify-content: space-between;
}

.avatarStyle {
  height: 24px !important;
  width: 24px !important;
  font-size: 12px !important;
}

.smallDiv {
  margin-left: 12px;
  margin-top: 12px;
  width: 200px;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: #1f2129;
}

.footerButton {
  color: #536074 !important;
  font-size: 16px !important;
  margin-left: 20px !important;
}

.footerButtonDisabled {
  color: #a0a2ac !important;
  font-size: 16px !important;
  margin-left: 20px !important;
}

.footerDiv {
  font-weight: 500;
  font-size: 12px;
  color: #a0a2ac;
  margin-top: 8px;
}

.footerRowButton {
  font-weight: 500 !important;
  font-size: 12px !important;
  color: #1f2129 !important;
}
