.proNotice {
  padding: 8px 8px 0 8px;
  flex: 1;
  font-weight: 400;
  color: #1f2129;
  font-size: 13px;
  > div {
    border-radius: 8px;
    border: 3px solid #546fff;
    padding: 8px 8px 0;
    button {
      text-decoration: underline;
      font-size: 13px;
      padding-top: 2px;
      padding-left: 4px;
      padding-right: 4px;
    }
  }
}