.header {
  display: grid;
  grid-template-columns: 1fr auto;
  margin: 16px;
  .title {
    grid-row: 1;
    grid-column: 1;
    font-size: 24px;
    font-weight: 600;
    color: #1f2129;
    margin-bottom: 8px;
  }
  .desc {
    grid-row: 2;
    grid-column: 1;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
  }
  .actions {
    grid-row: 1/3;
    grid-column: 2;
    display: flex;
    align-items: end;
  }
}
