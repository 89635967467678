:root {
  --primary: #546fff;
  --success: #41a451;
  --warning: #ab6700;
  --default: #1f2129;
  --error: #d32f2f;

  --primary-bg: #546fff0f;
  --success-bg: #41a4510f;
  --warning-bg: #ab67000f;
}

body {
  margin: 0;
  font-family: "Inter", "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.9rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Remove this */
thead {
  background-color: #f3f4f7;
  font-size: small;
  color: #404558;
  padding: 16px;
}

/* Clearing defaults */
p {
  margin: inherit;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
