.root {
  .grid {
    min-height: 300px;
    border-radius: 0;

    :global(.MuiDataGrid-cell),
    :global(.MuiDataGrid-columnHeader) {
      font-size: 12px;
      &:focus-within {
        outline: none;
      }
    }

    :global(.MuiCheckbox-root) svg {
      font-size: 16px;
    }
    :global(.MuiDataGrid-columnHeader) {
      :global(.MuiCheckbox-root) {
        display: none;
      }
      &:hover :global(.MuiCheckbox-root),
      :global(.Mui-checked.MuiCheckbox-root) {
        display: block;
      }
    }
    .docType {
      text-transform: capitalize;
    }
    .actionsCell {
      justify-content: center;
      svg {
        font-size: 16px;
      }
      .more {
        display: block;
        margin: auto;
      }
      .action {
        display: none;
        svg {
          margin-right: 6px;
        }
        &:hover {
          color: var(--primary);
        }
      }
      &:hover {
        .more {
          display: none;
        }
        .action {
          display: block;
        }
      }
    }
  }
}
