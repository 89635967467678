.filter {
  .btn {
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    color: #536074;
    background: #f5f6f7;
    white-space: nowrap;
    :global(.MuiButton-endIcon) {
      font-size: 14px;
    }
    &.active,
    &.open {
      color: #f5f6f7;
      background: #536074;
    }
    &.open {
      :global(.MuiButton-endIcon) {
        transform: rotateZ(180deg);
      }
    }
  }
  svg.endIcon {
    font-size: 14px;
  }
}

:global(.MuiPopover-root) {
  .popover {
    box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    .autocomplete {
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .title {
        font-weight: 600;
        font-size: 12px;
        color: #1f2129;
      }
      input {
        width: 100%;
      }
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 8px;
        li {
          cursor: pointer;
          list-style: none;
          display: flex;
          gap: 8px;
          align-items: center;
          span {
            padding: 0;
          }
          .name {
            font-size: 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          .Paid {
            color: var(--success);
            background-color: var(--success-bg);
          }
          .Received {
            color: var(--primary);
            background-color: var(--primary-bg);
          }
          .Rejected {
            color: var(--error);
            background-color: var(--warning-bg);
          }
          .Pending {
            color: var(--warning);
            background-color: var(--warning-bg);
          }
        }
      }
      fieldset {
        top: 0;
        legend {
          display: none;
        }
      }
      // raw string filter
      .endIcon {
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}
