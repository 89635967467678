.root {
  .dialogTitle {
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    margin: 16px 0px 6px 16px;
    color: #1f2129;
  }

  .fileDrop {
    background: #f8faff;
    text-align: center;
    padding: 64px 0px;
    border: 1px dashed #7e86a0;
    border-radius: 8px;
    margin: 16px;
  }

  .textDiv {
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #1f2129;
  }

  .selectButton {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .divider {
    margin: 32px 32px 32px 32px !important;
    font-weight: 400 !important;
    font-size: 10px !important;
    text-align: center !important;
    color: #7e86a0 !important;
  }
}
