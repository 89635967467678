.buttonClose {
  justify-content: center !important;
  align-items: center !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  background: #f5f6f7 !important;
  border-radius: 4px !important;
  color: #536074 !important;
  white-space: nowrap !important;
}

.buttonOpen {
  justify-content: center !important;
  align-items: center !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  background: #536074 !important;
  border-radius: 4px !important;
  color: #f5f6f7 !important;
  white-space: nowrap !important;
}

.titleDiv {
  font-weight: 600;
  font-size: 16px;
  color: #1f2129;
}

.label {
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 100% !important;
  color: #1f2129 !important;
}

.confirmation {
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: -4px;
  gap: 8px;
  align-items: center;
  font-weight: 400;
  font-size: 10px;
  color: #1f2129;
  cursor: default;
  span {
    padding: 0;
  }
}

.currency {
  padding: 0;
  margin-left: 20px;
  :global(.MuiSelect-select) {
    margin: 0 8px;
    padding: 2px 16px 2px 4px;
    font-weight: 400;
    font-size: 12px;
    // background-color: #eee;
    & ~ svg {
      font-size: 14px;
    }
  }
}

.currencyItem {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.Paid {
  margin-left: 10px;
  padding: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: var(--success);
  background-color: var(--success-bg);
}
.Received {
  margin-left: 10px;
  padding: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: var(--primary);
  background-color: var(--primary-bg);
}
.Rejected {
  margin-left: 10px;
  padding: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: var(--error);
  background-color: var(--warning-bg);
}
.Pending {
  margin-left: 10px;
  padding: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: var(--warning);
  background-color: var(--warning-bg);
}
