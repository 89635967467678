.root {
  margin: 16px;
  display: flex;
  gap: 16px;
  .filters {
    flex: 1;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }
  .clear {
    button svg {
      font-size: 16px;
    }
  }
}
