.root {
  display: flex;
  justify-content: space-between;
  padding: 24px;
  border: 1px solid #ecedf1;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  align-items: start;
  .value {
    font-weight: 600;
    font-size: 24px;
    color: #404558;
  }
  .label {
    margin-top: 6px;
    font-weight: 500;
    font-size: 12px;
    color: #7e86a0;
  }
  .additional {
    padding: 6px 8px;
    background: #f5f6f7;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    color: #404558;
  }
}
