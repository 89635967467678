.root {
  .tiles {
    margin: 16px;
    display: flex;
    gap: 16px;
    > div {
      flex: 1;
    }
  }
  .content {
    border-top: 1px solid #cccc;
  }
}
