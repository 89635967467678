@import "../../../lib/styles/invoices/status.scss";

%deleteItem {
  flex: 1;
  padding: 4px;
  .invoiceHeader {
    display: flex;
    align-items: center;
    gap: 8px;
    .vendor {
      font-weight: 500;
      font-size: 12px;
      color: #1f2129;
    }
    .status {
      @extend %status;
      // padding: 4px 6px;
      font-weight: 400;
      font-size: 12px;
      border-radius: 4px;
    }
    .floatRightButton {
      margin-left: auto;
    }
  }
  .invoiceDetails {
    display: flex;
    justify-content: space-between;
    .info {
      font-weight: 400;
      font-size: 10px;
      color: #7e86a0;
    }
    .amount {
      font-weight: 400;
      font-size: 12px;
      color: #1f2129;
    }
  }
}

.root {
  padding: 16px;
  width: 550px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  .title {
    font-weight: 600;
    font-size: 16px;
    cursor: default;
  }
  .invoiceList {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    // width: 100%;
    background: #f5f6f7;
    border-radius: 4px;
    .header {
      cursor: pointer;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
      color: #1f2129;
      > div {
        flex: 1;
      }
    }
    .invoice {
      @extend %deleteItem;
    }
  }
  .comment {
    fieldset {
      top: 0;
      legend {
        display: none;
      }
    }
  }
  .confirmation {
    display: flex;
    gap: 8px;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    color: #1f2129;
    cursor: default;
    span {
      padding: 0;
    }
  }
  .footer {
    display: flex;
    gap: 8px;
    color: grey;
  }
}

// .Paid {
//   color: var(--success);
//   background-color: var(--success-bg);
// }
// .Fetched {
//   color: var(--primary);
//   background-color: var(--primary-bg);
// }
// .Rejected {
//   color: var(--error);
//   background-color: var(--warning-bg);
// }
// .Approved {
//   color: var(--warning);
//   background-color: var(--warning-bg);
// }
// .First_Approved {
//   color: var(--warning);
//   background-color: var(--warning-bg);
// }
// .Second_Approved {
//   color: var(--warning);
//   background-color: var(--warning-bg);
// }
