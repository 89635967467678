// The following logic is used in several places
%status {
  font-weight: 500;
  gap: 4px;
  justify-content: space-between;
  > div {
    padding: 4px 6px;
    border-radius: 4px;
  }
  &:global(.Fetched) > div {
    color: var(--primary);
    background-color: var(--primary-bg);
  }
  &:global(.First_Approved) > div {
    color: var(--warning);
    background-color: var(--warning-bg);
  }
  &:global(.Second_Approved) > div {
    color: var(--warning);
    background-color: var(--warning-bg);
  }
  &:global(.Approved) > div {
    color: var(--warning);
    background-color: var(--warning-bg);
  }
  &:global(.Paid) > div {
    color: var(--success);
    background-color: var(--success-bg);
  }
  &:global(.Rejected) > div {
    color: var(--error);
    background-color: var(--warning-bg);
  }
  &:global(.Unpaid) > div {
    color: var(--primary);
    background-color: var(--primary-bg);
  }
}
