
// Tooltip styles
:global(.MuiTooltip-tooltip) {
  &.tooltip {
    background-color: white;
    color: #1f2129;
    font-weight: 400;
    font-size: 12px;
    padding: 8px;
    box-shadow: 2px 2px 14px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
  }
  .title {
    display: flex;
    gap: 4px;
    align-items: center;
  }
}
