.success {
  color: var(--success);
  svg {
    font-size: 16px;
  }
}
.warning {
  color: var(--warning);
  svg {
    font-size: 16px;
  }
}
